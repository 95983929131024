import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

let count = 0;

class MapContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      showInfos: false,
      markers: this.props.markers,
      zoom: 7,
      center: {
        lat: 52.3667,
        lng: 4.8945
      }
    };

  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.markers.length != this.props.markers.length) return true;
    if (nextProps.zoom != this.props.zoom) return true;
    if (nextProps.center != this.props.center) return true;

    return false;
  }


  componentWillReceiveProps(nextProps) {
    // console.log('next props', nextProps);
    this.setState({
      key: count,
      markers: nextProps.markers,
    });

    if (nextProps.zoom !== this.state.zoom) {
      this.setState({
        zoom: nextProps.zoom,
        key: count++
      })
    }

    if (nextProps.center !== this.state.center) {
      this.setState({
        center: nextProps.center,
        key: count++
      })
    }

    // console.log('showInfos: ', nextProps.showInfos)
  }

  renderMarkers = (map, maps, markers) => {
    let contentString;
    let infowindow = new maps.InfoWindow();

    count = count + 1;
    // console.log(count);
    if (markers) {

      for (let i = 0; i < markers.length; i++) {
        // console.log('phone', this.state.markers[i].email.length);
        // let id = this.state.markers[i].id;
        let formattedPhoneNumber = markers[i].telephoneNumber.replace(/\s/g, '');
        if (markers[i].email.length > 0) {
          contentString = `
          <div className="" style="display:flex;">
            <div style="margin-right:10px; width: 50%">
              <p> ${markers[i].title} <br>${markers[i].address2} <br/> ${markers[i].address3} 
              </p>
            </div>
            <div style="width: 50%">
              <p><a href="tel:${formattedPhoneNumber}">Telefoon nr: <br><b>${markers[i].telephoneNumber}</b></a></p>
              <p><a href="mailto:${markers[i].email}">Email: <br><b>${markers[i].email}</b></a></p>
              <a href="${markers[i].website}" target="_blank" class="btn">Bezoek website</a>
            </div>
          </div>`;
        } else {
          contentString = `
          <div style="display:flex;">
            <div style="margin-right:10px; width: 50%">
              <p> ${this.state.markers[i].title} <br>${this.state.markers[i].address2} <br/> ${this.state.markers[i].address3} </p>
            </div>
            <div style="width: 50%">
              <p><a href="tel:${formattedPhoneNumber}">Telefoon nr: <br><b>${this.state.markers[i].telephoneNumber}</b></a></p>
              <a href="${this.state.markers[i].website}" target="_blank" class="btn">Bezoek website</a>
            </div>
          </div>`;

        }
        let marker = new maps.Marker({
          position: { lat: this.state.markers[i].lat, lng: this.state.markers[i].lng },
          map,
          info: contentString,
          icon: { url: '/location.png', scaledSize: new maps.Size(25, 39.75) },
          title: this.state.markers[i].title,
        });



        marker.addListener('click', function () {
          infowindow.setContent(this.info);
          infowindow.open(map, marker);
        });
      }
    } else {
      let marker = new maps.Marker({
        position: this.props.center,
        map,
        title: 'Hello World!'
      });
    }
  }


  render() {

    const { markers, key } = this.state;

    return (
      <div className="map-container" style={{ width: '100%', height: 450, backgroundColor: 'rgba(253, 153, 127, 0.1)' }}>
        <GoogleMapReact
          options={{
            styles: [
              { stylers: [{ 'saturation': -100 }, { 'gamma': 1.5 }] }
            ]
          }}
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API }}
          defaultCenter={this.props.center}
          center={this.state.center}
          defaultZoom={7}
          zoom={this.state.zoom}
          draggable={true}
          onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps, markers)}
          yesIWantToUseGoogleMapApiInternals={true}
          key={key}
        />
      </div>
    );
  }
}


export default MapContainer;
