import React, {Component} from 'react';
import {graphql} from 'gatsby';
import parse from 'html-react-parser';
import {db} from "../graphql/client.js";
import {gql} from 'apollo-boost'
import BaseLayout from '../components/Layout/BaseLayout';
import MapContainer from '../components/GoogleMap';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MobilePopup from '../components/MobilePopup';
import ResponsiveContainer from "../components/ResponsiveContainer";

// RENDER MAP FOR MOBILE
const RenderMap = (zoom, center, markers, i, showInfos, x) => {

  return (
    <>
      <div className="text-wrapper">
        {markers[showInfos].title && <h2>{markers[showInfos].title}</h2>}
        {markers[showInfos].address2 && <p>{markers[showInfos].address2}</p>}
        {markers[showInfos].address3 && <p>{markers[showInfos].address3}</p>}
        {markers[showInfos].phone && <a href={`tel:${markers[showInfos].phone}`}>Telefoon nr: <b>{markers[showInfos].phone}</b></a>}
        {markers[showInfos].website && <a className="btn" href={`mailto:${markers[showInfos].website}`}>Bezoek website<span /></a>}
      </div>
      <MapContainer markers={markers} showInfos={showInfos} zoom={zoom} center={center} />
    </>
  )
}

let expertiseCentra = [];

class ExpertiseCentra extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      filteredCentra: [],
      show: false,
      selectedId: 0,
      isMobile: false,
      showModal: false,
      showInfos: 0,
      markers: [
        {
          lat: 51.911479,
          lng: 4.469732,
          title: 'Erasmus Medisch Centrum',
          address1: 'Erasmus Medisch Centrum',
          address2: 's-Gravendijkwal 230',
          address3: '3015 CE Rotterdam',
          city: 'ROTTERDAM',
          telephoneNumber: '010 704 4840',
          website: 'https://www.erasmusmc.nl/nl-nl/sophia/patientenzorg/centra/lysosomale-en-metabole-ziekten',
          email: 'metaboolcentrum@erasmusmc.nl',
          showInfo: false,
          ziektes: ['Pompe', 'MPS-I (Hurler, Hurler-Scheie en Scheie)', 'MPS-II (Hunter)', 'MPS-IV (Morquio)', 'MPS-VII (Sly)', 'Neuronale ceroïd lipofuscinose ( NCL )', 'Alfa- en Bètamannosidose', 'Bèta-mannosidose', 'Salla', 'Krabbe', 'Aspartylglucosaminurie', 'Danon', 'Farber', 'Fucosidose', 'Galactosialidose', 'GM1-gangliosidose', 'GM2-gangliosidose (Sandhoff/Tay-Sachs)', 'ISSD', 'Mucolipidose-II (ML-II, I-celziekte)', 'Mucolipidose-III (ML-III)', 'Mucolipidose-IV (ML-IV)', 'Multiple Sulfatase Deficientie', 'Pycnodysostose', 'Schindler', 'Sialidose', 'LAL-D', 'MLD', 'Salla'],
        },
        {
          lat: 52.294538,
          lng: 4.958606,
          title: 'Sphinx - Het Amsterdam Lysosoom Centrum',
          address1: 'Sphinx - Het Amsterdam Lysosoom Centrum',
          address2: 'Meibergdreef 9',
          address3: '1105 AZ Amsterdam',
          city: 'AMSTERDAM',
          telephoneNumber: '020 566 9111',
          website: 'https://www.amc.nl/web/specialismen/bijzondere-zorg-expertisecentra/sphinx-1.htm',
          email: 'Sphinx@amc.uva.nl',
          showInfo: false,
          ziektes: ['Alfa-mannosidose', 'Gaucher', 'Fabry', 'MPS-I (Hurler, Hurler-Scheie en Scheie)', 'MPS-III (Sanfilippo)', 'MPS-IV (Morquio)', 'MPS-VII (Sly)', 'Lysosomale zure-lipasedeficiëntie (LAL-D)',  'Niemann-Pick A&B', 'Niemann-Pick type C', 'Aspartylglucosaminurie', 'Danon', 'Fucosidose', 'Galactosialidose', 'GM1-gangliosidose', 'GM2-gangliosidose (Sandhoff/Tay-Sachs)', 'ISSD', 'Mucolipidose-IV (ML-IV)', 'Pycnodysostose', 'Schindler', 'Sialidose', 'LAL-D'],
        },
        {
          lat: 52.336471,
          lng: 4.859530,
          title: 'Centrum voor Kinder-Wittestofziekten',
          address1: 'Centrum voor Kinder-Wittestofziekten',
          address2: 'De Boelelaan 1117',
          address3: '1081 HV Amsterdam',
          city: 'AMSTERDAM',
          telephoneNumber: '020 444 4856',
          website: 'https://www.vumc.nl/zorg/expertisecentra-en-specialismen/centrum-voor-kinderen-met-wittestofziekten.htm',
          email: 'whitemattercenter.info@vumc.nl',
          showInfo: false,
          ziektes: ['MLD', 'Krabbe'],
        },
        {
          lat: 51.823867,
          lng: 5.860274,
          title: 'Expertisecentrum Zeldzame Nierziekten',
          address1: 'RadboudUMC',
          address2: 'G. Grooteplein-Zuid 10,',
          address3: '6525 GA Nijmegen',
          city: 'NIJMEGEN',
          telephoneNumber: '024 361 4430',
          email: '',
          website: 'https://www.radboudumc.nl/expertisecentra/zeldzame-nierziekten',
          showInfo: false,
          ziektes: ['Cystinose'],
        },
        {
          lat: 52.084298,
          lng: 5.231505,
          title: 'Bartiméus',
          address1: 'Bartiméus',
          address2: 'Van Renesselaan 30a',
          address3: '3703 AJ Zeist',
          city: 'ZEIST',
          telephoneNumber: '088 889 9888',
          website: 'https://www.bartimeus.nl/specialistische-kennis/ncl-en-bartim%C3%A9us',
          email: 'aanmelding@bartimeus.nl',
          showInfo: false,
          ziektes: ['Neuronale ceroïd lipofuscinose (NCL)'],
        }
      ],
      zoom: 7,
      center: {
        lat: 52.3667,
        lng: 4.8945
      },
      diseases: {}
    };

  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 991 });
  }

  componentDidMount() {
    this.setState({
      show: true,
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.throttledHandleWindowResize);
      this.setState({ isMobile: window.innerWidth < 991 });
    };
    expertiseCentra = JSON.parse(JSON.stringify(this.state.markers));
    this.setState({ filteredCentra: JSON.parse(JSON.stringify(this.state.markers)) })

    db.query({ query: gql`{diseases { id, name }}`})
      .then(response => {
        let { diseases } = response.data;
        const flattened = diseases.map(disease => disease.name);
        this.setState({ diseases: flattened })
      })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.throttledHandleWindowResize);
    };
  }


  // handle click on expertise centra list
  // zoom in the clicked expertise centrum on Google Maps
  clickHandle = (x) => {
    let markers = JSON.parse(JSON.stringify(this.state.filteredCentra));
    markers.map((marker) => marker.showInfo = false);
    markers[x].showInfo = true;

    this.setState({
      zoom: 17,
      center: { lat: markers[x].lat, lng: markers[x].lng },
      showModal: true,
      showInfos: x,
    });
  };

  matchPartial(query) {
    let q = query.replace(/\(([^)]+)\)/g, '');
    return new RegExp(`(?:^|\\s)${q}`, 'gi')
  }

  /**
   * filter centra based on query
   * @todo: match on id of disease
   **/
  filterCentra(query){

    let regexp = this.matchPartial(query);

    let matchCentra = this.state.markers.map((centrum, i) => {

      let matched = centrum.ziektes.map((ziekte, i) => {

        return (regexp.test(ziekte)) ? centrum : false;
      }).filter(Boolean);

      return (matched.length >=1) ? matched : false;

    }).filter(Boolean);

    expertiseCentra = matchCentra.flatMap(item => item);
    this.setState({ filteredCentra: expertiseCentra });

  }

  // on change handler of search bar
  onChange = e => {

    const userInput = e.currentTarget.value;
    const filteredSuggestions = this.state.diseases.filter(disease => {

      let regexp = this.matchPartial(userInput);
      let found = regexp.test(disease);

      return found;

    });

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });

    if (e.currentTarget.value === '') {
      expertiseCentra = JSON.parse(JSON.stringify(this.state.markers));
      this.setState({
        filteredCentra: JSON.parse(JSON.stringify(this.state.markers)),
        zoom: 7
      })
    }

  };

  // on click of suggested disease in the
  onClick = e => {

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    // let expertiseCentra = [];

    this.filterCentra(e.currentTarget.innerText)

  };

  onKeyDown = e => {
    // const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: this.state.filteredSuggestions[this.state.activeSuggestion]
      });

      this.filterCentra(this.state.filteredSuggestions[this.state.activeSuggestion])

    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {

      if (this.state.activeSuggestion === 0) return;

      this.setState({ activeSuggestion: this.state.activeSuggestion - 1 });
    }

    // User pressed the down arrow
    else if (e.keyCode === 40) {

      if(this.state.filteredSuggestions.length === 1) {
        this.setState({ activeSuggestion: 0});
        return;
      }

      if (this.state.activeSuggestion - 1 === this.state.filteredSuggestions.length) return;

      this.setState({ activeSuggestion: this.state.activeSuggestion + 1 });
    }
  };

  render() {
    const { data, location } = this.props;
    const { zoom, center, markers, filteredCentra, showInfos, showModal, activeSuggestion, filteredSuggestions, showSuggestions, userInput } = this.state;
    const { onChange, onClick, onKeyDown } = this;

    // console.log(data.gravql.pages[1].children[2].children)
    // console.log('filtered centra: ', this.state);

    let suggestionsListComponent;

    // render suggestion list based on the user iput and the list of all diseases
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // add active classname to active suggestion
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={index} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Geen suggesties</em>
          </div>
        );
      }
    }

    return (
      <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}>
        <div className="expertiseCentra">
          {
            parse(data.gravql.page.content)
          }
          <Row>
            <Col sm={12} lg={4}>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col sm={12} lg={4}>*/}

          {/*    /!* INPUT WITH SEARCH LOCATIONS *!/*/}
          {/*    <div className="autocomplete-wrapper">*/}
          {/*      <input*/}
          {/*        type="text"*/}
          {/*        placeholder="Zoek op ziektebeeld"*/}
          {/*        onChange={onChange}*/}
          {/*        onKeyDown={onKeyDown}*/}
          {/*        value={userInput}*/}
          {/*      />*/}
          {/*      {suggestionsListComponent}*/}
          {/*      {userInput ? <span id='hide' onClick={()=> this.setState({userInput: '', filteredCentra: JSON.parse(JSON.stringify(this.state.markers))})}/> : <span id='show' />}*/}

          {/*    </div>*/}

          {/*    /!* LOCATIONS *!/*/}
          {/*    <p className="location">Locaties</p>*/}
          {/*    <ul className="locations">*/}
          {/*      {*/}
          {/*        filteredCentra.map((child, x) => {*/}
          {/*          // @fix: multiple modals were made in loop*/}
          {/*          return <li key={child.telephoneNumber} onClick={() => this.clickHandle(x)}><p>{child.title}</p><p className="small">{child.city}</p></li>*/}
          {/*        })*/}
          {/*      }*/}
          {/*    </ul>*/}

          {/*  </Col>*/}

          {/*  <Col sm={12} lg={8} >*/}
          {/*    {!this.state.isMobile && <MapContainer markers={filteredCentra} showInfos={showInfos} zoom={zoom} center={center} />}*/}
          {/*  </Col>*/}

          {/*</Row>*/}
        </div>
        <ResponsiveContainer>
          <MobilePopup
            open={showModal}
            close={() => {
              this.setState({ showModal: false })
            }}
          >
            {/*{RenderMap(zoom, center, markers, filteredCentra, showInfos, 0)}*/}
          </MobilePopup>
        </ResponsiveContainer>

      </BaseLayout>
    )
  }
}

export default ExpertiseCentra;


export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                    header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route       
                 children {
                    title
                    route
                }         
            }          
            metadata {
                name
                content
            }   
        }
    }
  }
`
